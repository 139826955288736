import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import TopNavBar from "./TopNavBar.js";
import About from "./About.js";
import Contact from "./Contact.js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <div className="AppContainer">
      <Router>
        <TopNavBar />
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/">
            <About />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
