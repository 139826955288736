import "./Contact.css";
import mailIcon from "./gmail.png";
import fbIcon from "./facebook.png";
import linkedinIcon from "./linkedin.png";
import lichessIcon from "./lichess.svg";

export default function Contact() {
  return (
    <div>
      <p className="ContactBlurb">
        You can get in touch with me in one of the following ways - or challenge
        me in lichess. I prefer email, as old fashioned as it sounds.
      </p>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a href="mailto:adiguru2@gmail.com" data-title="gmail">
          <img src={mailIcon} alt="gmail" className="ContactIcon" />
        </a>

        <a
          href="https://www.facebook.com/aditya.gurunathan"
          data-title="facebook"
          className="ContactContainer"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={fbIcon} alt="facebook" className="ContactIcon" />
        </a>

        <a
          href="https://www.linkedin.com/in/adiguru/"
          data-title="linkedin"
          className="ContactContainer"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={linkedinIcon} alt="linkedin" className="ContactIcon" />
        </a>

        <a
          href="https://lichess.org/@/adiguru2"
          data-title="linkedin"
          className="ContactIconLichessContainer"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={lichessIcon} alt="lichess" className="ContactIconLichess" />
        </a>
      </div>
    </div>
  );
}
