import "./About.css";
import profilePhoto from "./profile_photo.jpg";

export default function About() {
  return (
    <div className="ProfileContainer">
      <h3 className="ProfileHeading">
        Welcome to my tiny island on the internet!
      </h3>
      <div>
        <div className="PhotoContainer">
          <img src={profilePhoto} className="ProfilePhoto" alt="avatar" />
        </div>
        <div className="ProfileAboutContainer">
          <p>
            Hey, I am Aditya. I have been working as a Software Engineer at
            Facebook London since 2016 and currently I am supporting the safety
            efforts inside the Integrity org.
            <br />
            <br />
            Before joining Facebook, I spent four years at{" "}
            <a href="https://www.iitm.ac.in/" target="_blank" rel="noopener noreferrer">
              IIT Madras
            </a>
            , where I studied Electrical Engineering, after spending my
            formative years in the town of{" "}
            <a
              href="https://en.wikipedia.org/wiki/Mayiladuthurai"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mayiladuthurai
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
