import "./TopNavBar.css";
import NavBar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import { useHistory } from "react-router-dom";

export default function TopNavBar() {
  let history = useHistory();
  return (
    <div className="NavContainer">
      <NavBar bg="light">
        <Nav>
          <Nav.Link onClick={() => history.push("/about")}>Home</Nav.Link>
          <Nav.Link onClick={() => history.push("/contact")}>Contact</Nav.Link>
        </Nav>
      </NavBar>
    </div>
  );
}
